import React, { useState } from "react";
import {
  Row,
  Col,
  Input,
  Select,
  Collapse,
  Icon,
  DatePicker,
  Button,
} from "antd";
import { withTranslation } from "react-i18next";
import moment from "moment";
import useShipments from "../../hooks/useShipments";

const { Panel } = Collapse;
const { Option } = Select;

function Search({
  searchParameters,
  advancedSearchVisible,
  handleSearchShipments,
  handleDateRangeChange,
  handleSearchInputChange,
  handleTypeChange,
  handleToggleAdvancedSearch,
}) {
  const renderInputLabel = () => {
    switch (searchParameters.type) {
      case "shipmentCode":
        return "Código de envío";
      case "numRefClient":
        return "Código de referencia";
      default:
        return "";
    }
  };

  const isSearchDisabled = () => {
    if (!searchParameters.value.trim() && !advancedSearchVisible) {
      return true;
    } else if (
      advancedSearchVisible &&
      (!searchParameters.startValue || !searchParameters.endValue)
    ) {
      return true;
    }
    return false;
  };

  const disabledStartDate = (startValue) => {
    const { endValue } = searchParameters;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  const disabledEndDate = (endValue) => {
    const { startValue } = searchParameters;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  return (
    <div className="Busca">
      <Row>
        <h2>Buscador de envíos</h2>
        <Collapse
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel
            header="Buscador"
            key="1"
            extra={<Icon type="minus-circle" />}
            className="Busca"
          >
            <Row>
              <form onSubmit={handleSearchShipments}>
                <Col lg={6}>
                  <label>Tipo de búsqueda</label>
                  <br />
                  <Select
                    defaultValue="shipmentCode"
                    onChange={handleTypeChange}
                    style={{ width: "90%" }}
                  >
                    <Option value="shipmentCode">Envío</Option>
                    <Option value="numRefClient">Referencia del cliente / pedido</Option>
                  </Select>
                </Col>
                <Col lg={12}>
                  <label>{renderInputLabel()}</label>
                  <br />
                  <Input
                    type="text"
                    value={searchParameters.value}
                    onChange={handleSearchInputChange}
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col lg={5}>
                  <br />
                  <Button
                    type="submit"
                    htmlType="submit"
                    disabled={isSearchDisabled()}
                  >
                    Buscar
                  </Button>
                </Col>
              </form>
            </Row>
            <div style={{ marginTop: "1em" }}>
              <span
                style={{ cursor: "pointer", color: "blue" }}
                onClick={handleToggleAdvancedSearch}
              >
                {advancedSearchVisible
                  ? "Ocultar búsqueda avanzada "
                  : "Mostrar búsqueda avanzada "}
                <Icon type={advancedSearchVisible ? "up" : "down"} />
              </span>
              {advancedSearchVisible && (
                <div className="dateRange">
                  <h4>Buscador fecha alta en plataforma</h4>
                  <Row className="row" type="flex">
                    <Col lg={6} className="col" style={{ marginRight: "0" }}>
                      <label>Desde</label>
                      <br />
                      <DatePicker
                        disabledDate={disabledStartDate}
                        format="DD/MM/YYYY"
                        value={searchParameters.startValue}
                        onChange={(value) =>
                          handleDateRangeChange("startValue", value)
                        }
                        style={{ width: "90%" }}
                      />
                    </Col>
                    <Col lg={6} className="col">
                      <label>Hasta</label>
                      <br />
                      <DatePicker
                        disabledDate={disabledEndDate}
                        format="DD/MM/YYYY"
                        value={searchParameters.endValue}
                        onChange={(value) =>
                          handleDateRangeChange("endValue", value)
                        }
                        style={{ width: "90%" }}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Panel>
        </Collapse>
      </Row>
    </div>
  );
}

export default withTranslation()(Search);
