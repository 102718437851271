import { Icon, Modal, Spin } from "antd";
import React, { Component } from "react";

class Loader extends Component {
    render() {
        // const { t } = this.props;
        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
        return (
            <Modal
                visible
                centered
                closable={false}
                footer={null}
            >
                <div className="paperReloj">
                    <Spin indicator={antIcon} tip="Loading..." />
                </div>
            </Modal>
        )
    }
}

export default Loader