import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

const LoginCallback = () => {
  const queryParams = useLocation();
  const history = useHistory();
  
  const search = queryParams.search;

  const refreshToken = new URLSearchParams(search).get('refreshToken') ?? null;
  const idToken = new URLSearchParams(search).get('idToken') ?? null;
  
  useEffect(() => {
    if (idToken != null && refreshToken != null) {
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('idToken', idToken);
      history.push(`/authorize`);
    } else {
      history.push(`/login`);
    }
  }, [])
  return <div />
}

export default LoginCallback