/* eslint-disable prettier/prettier */
import React, { FunctionComponent } from 'react';
import { Menu, Icon } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import { withTranslation } from 'react-i18next';
import { Logout } from 'correos-arq-auth';
import { storeArq } from 'correos-arq-utils';
import useLogout from '../../Api/Auth/useLogout';

const MUser = (myProps) => {
  try {
    const { t } = myProps;

    let classLogoutSys = "botones flexDisplay";

    const { logout } = useLogout();

    // Coger fecha y hora del sistema actual
    const getDate = () => {
      setInterval(function () {
        document.getElementById('hora').innerText = moment()
          .locale('es')
          .format('dddd, DD/MM/YYYY HH:mm:ss');
      }, 1000);
    };

    return (
      <div className="MUser" onLoad={getDate()}>
        <Menu>
          <Menu.Item key="1" className="datos">
            <h4>{storeArq.data.auth.userInfo.identificador}</h4>
            <p id="hora" />
            <p>{storeArq.data.auth.userInfo.codired}</p>
            <p>
              {storeArq.data.auth.userInfo.nombre}{' '}
              {storeArq.data.auth.userInfo.apellidos}
            </p>
            <p>{storeArq.data.auth.userInfo.rol}</p>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="3" onClick={() => logout()} className={classLogoutSys}>
            <Icon type="logout" color="secondary" className="iconoAuth" />
            {t('components_shared_MUser.logout')}
          </Menu.Item>
        </Menu>
      </div>
    );
  } catch (error) {
    throw new Error(`Error: ${error}`);
  }
}

export default withTranslation()(MUser);
