import React, { useState, useContext, useEffect } from "react";
import { Alert, Button, Modal } from "antd";
import { CookieContext } from "../../context/CookieContext";

const CookieBanner = () => {
  const contextValue = useContext(CookieContext)
  const [showBanner, setShowBanner] = useState(contextValue);
  
  const allDocuments = [
    {
      url: "../../documents/PoliticaCookies.html",
      name: "Política de Cookies",
    },
    {
      url: "../../documents/PrivacidadWeb.html",
      name: "Política de privacidad web",
    }
  ];
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({ name: '', htmlContent: '' });

  const openModal = (url, name) => {
    fetch(url)
      .then(response => response.text())
      .then(htmlContent => setModalContent({ name, htmlContent }))
      .catch(error => {
        console.error('Error fetching documento:', error);
        setModalContent({ name: '', htmlContent: '' });
      });

    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalContent({ name: '', htmlContent: '' });
  };

  const handleModalLinkClick = (e) => {
    e.preventDefault();
    const targetUrl = e.target.getAttribute('href');

    const matchedDocument = allDocuments.find(doc => doc.url === targetUrl);

    if (matchedDocument) {
      openModal(matchedDocument.url, matchedDocument.name);
    }
  };

  useEffect(() => {
    if (modalVisible) {
      setModalVisible(false); // Cerramos el modal para reiniciar su contenido
      setTimeout(() => setModalVisible(true), 100); // Abrimos el modal después de un breve retraso
    }
  }, [modalContent]);

 
  useEffect(()=>{
    setShowBanner(contextValue)
  }, [contextValue])

  const handleConfirm = () => {
    setShowBanner(false);
    const cookie_acceptance = {
      a: btoa(true),
    };
    
    // Convertir el objeto a formato JSON
    const cookieJSON = JSON.stringify(cookie_acceptance);

    const infoUser = JSON.parse(sessionStorage.getItem("storeArq.data"));
    const id = infoUser?.auth?.userInfo?.identificador;
    const id64 = btoa(id);//id del session storage

    // Guardar el objeto JSON en el Local Storage
    localStorage.setItem(id64, cookieJSON);
  };

  
  
  return (
    <div className="cookie-banner-container">
      {showBanner && (
        <Alert
          message="AVISO DE COOKIES"
          description={
            <>
              <span>
                Esta página web, propiedad de Correos, utiliza únicamente
                cookies propias con una finalidad técnica, no recaba ni cede
                datos de carácter personal de los usuarios sin su conocimiento.
                Puedes obtener más información en nuestra{" "}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(allDocuments[0].url, allDocuments[0].name);
                  }}
                >Política de Cookies</a>
                .
              </span>
              <Button
                className="cookie-banner-button"
                type="primary"
                onClick={handleConfirm}
              >
                Entendido
              </Button>
            </>
          }
          type="info"
          showIcon
        />
      )}
       <Modal
        title={modalContent.name}
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
        width='66%'
      >
        <div onClick={handleModalLinkClick}>
          <div dangerouslySetInnerHTML={{ __html: modalContent.htmlContent }} />
        </div>
      </Modal>
    </div>
  );
};

export default CookieBanner;
