import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import useShipments from "../../hooks/useShipments";
import DetailedTracksTable from "../../components/DetailedTracksTable/DetailedTracksTable";
import Search from "../../components/Search/Search";

import moment from "moment";
import Alerts from "../../components/Alerts/Alerts";

function Home() {
  const [searchParameters, setSearchParameters] = useState({
    startValue: null,
    endValue: null,
    onDateRangePicked: false,
    onParameterPicked: false,
    searchWasMade: false,
    value: "",
    type: "shipmentCode",
    shipmentCode: null,
    numRefClient: null,
  });

  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);

  const { fetchShipments, shipmentData, telemetryInfo, fetchShipmentPod } =
    useShipments(null);

  const checkValue = (value) => {
    return value === "" ? null : value;
  };

  const handleDateRangeChange = (field, value) => {
    setSearchParameters((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleSearchInputChange = (event) => {
    const { value } = event.target;
    setSearchParameters({ ...searchParameters, value });
    setSearchParameters((prevState) => ({
      ...prevState,
      [searchParameters.type]: value.trim(),
    }));
  };

  const handleTypeChange = (value) => {
    setSearchParameters({ ...searchParameters, type: value, value: "" });
    const prevType = searchParameters.type;
    setSearchParameters((prevState) => ({
      ...prevState,
      [prevType]: "",
    }));
  };

  const handleToggleAdvancedSearch = () => {
    if (advancedSearchVisible) {
      setSearchParameters((prevState) => ({
        ...prevState,
        startValue: null,
        endValue: null,
      }));
    } else {
      setSearchParameters((prevState) => ({
        ...prevState,
        startValue: moment().add(-7, "days"),
        endValue: moment(),
      }));
    }
    setAdvancedSearchVisible(!advancedSearchVisible);
  };

  const handleSearchShipments = (e) => {
    e.preventDefault();
    if (!searchParameters.searchWasMade) {
      setSearchParameters((prevState) => ({
        ...prevState,
        searchWasMade: true,
      }));
    }

    if (
      searchParameters.shipmentCode ||
      searchParameters.numRefClient ||
      (searchParameters.startValue && searchParameters.endValue)
    ) {
      setSearchParameters((prevState) => ({
        ...prevState,
        shipmentCode: checkValue(searchParameters.shipmentCode),
        numRefClient: checkValue(searchParameters.numRefClient),
        onParameterPicked: true,
        onDateRangePicked: true,
      }));
    }
  };

  return (
    <div className="Home">
      <Search
        searchParameters={searchParameters}
        handleDateRangeChange={handleDateRangeChange}
        handleSearchShipments={handleSearchShipments}
        handleSearchInputChange={handleSearchInputChange}
        handleTypeChange={handleTypeChange}
        advancedSearchVisible={advancedSearchVisible}
        handleToggleAdvancedSearch={handleToggleAdvancedSearch}
      />
      {searchParameters.searchWasMade && (
        <>
          <Alerts
            searchParameters={searchParameters}
            shipmentData={shipmentData}
            telemetryInfo={telemetryInfo}
          />
          <DetailedTracksTable
            searchParameters={searchParameters}
            setSearchParameters={setSearchParameters}
            fetchShipments={fetchShipments}
            fetchShipmentPod={fetchShipmentPod}
            shipmentData={shipmentData}
          />
        </>
      )}
    </div>
  );
}

export default withTranslation()(Home);
