import React from "react";
import {
  Row,
  Input,
  Button,
  Table,
  Form,
  Tag,
  Icon,
  Pagination,
  Skeleton,
  Spin,
  Tooltip,
} from "antd";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as PODIcon } from "../../../public/images/signature.svg";

const EditableContext = React.createContext();
const events = ["Preadmisión", "En camino", "En entrega", "Entregado"];
let self;

let green = "#3f8600";
let red = "#ca0909";

class DetailedTracksTable extends React.Component {
  constructor(props) {
    super(props);

    const { startValue, endValue, onDateRangePicked } =
      this.props.searchParameters;
    this.state = {
      message: "Cargando...",
      statusCode: 0,
      dataSource: [],
      count: 0,
      searchText: "",
      searchedColumn: "",
      editingKey: "",
      showDeleteModal: false,
      dataToShow: [],
      currentPage: 1,
      status: "loading",
      startValue: startValue,
      endValue: endValue,
      size: 10,
      paginatedSearch: false,
      mustShowTable: false,
    };

    self = this;
  }

  formatShipmentCode(code) {
    const newCode = code.substr(0, code.length / 2);
    const newCode2 = code.substr(code.length / 2, code.length - 1);
    return (
      <div style={{ padding: "0 1rem", alignItems: "center" }}>
        <span>{newCode}</span>
        <span style={{ display: "block" }}>{newCode2}</span>
      </div>
    );
  }

  dataloggerType(standalone) {
    let txt = standalone ? "Stand-Alone" : "On-Line";
    return txt;
  }

  eventNameColor(param, date) {
    let color;
    switch (param) {
      case "Preadmisión":
        color = "red";
        break;
      case "En camino":
        color = "blue";
        break;
      case "En entrega":
        color = "cyan";
        break;
      case "Entregado":
        color = "green";
        break;
      default:
        color = "volcano";
        break;
    }
    color = "blue";
    let txt = param;

    if (param) {
      let arrayDeCadenas = param.split(":");
      txt = arrayDeCadenas[0];
    }

    return (
      <Tag color={color} key={param}>
        <span style={{ whiteSpace: "pre-wrap" }}>{txt}</span>
      </Tag>
    );
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 190, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reiniciar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }

  handleReset(clearFilters) {
    clearFilters();
    this.setState({
      searchText: "",
    });
  }

  handlePagChange(page, size) {
    let { dataToShow } = self.state;

    self.setState({
      dataToShow: dataToShow,
      currentPage: page,
      size: size,
      paginatedSearch: true,
    });
  }

  handlePagSizeChange(current, size) {
    if (self.props.shipmentData.totalResults === self.state.dataSource.length) {
      if (size > self.state.dataSource.length) {
        return false;
      }
    }

    self.setState({ size: size, currentPage: 1, paginatedSearch: true });
  }

  calculateTotalAlerts = (record) => {
    const { warningsTemp, warningsImpact, warningsOpen } = record;
    let totalAlerts = warningsTemp + warningsImpact + warningsOpen;

    const alertMessage = totalAlerts > 0 ? "Sí" : "No";

    const alertColor = totalAlerts > 0 ? red : green;

    return <span style={{ color: alertColor }}>{alertMessage}</span>;
  };

  renderMacData = (mac) => {
    if (mac && mac !== "N/D") {
      return (
        <>
          <br />
          <span style={{ color: "#A0A0A0" }}>({mac})</span>
        </>
      );
    }

    return null;
  };

  formatDate(date) {
    let formattedDate = new Date(date);

    if (formattedDate == "Invalid Date") {
      return "N/D";
    }

    return formattedDate
      .toLocaleDateString("es-Es", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(",", "");
  }

  handleSearchOrders(searchParameters) {
    const {
      shipmentCode,
      numRefClient,
      onDateRangePicked,
      onParameterPicked,
      startValue,
      endValue,
    } = searchParameters;

    if (onDateRangePicked || onParameterPicked) {
      const defaultCurrentPage = 1;

      this.props.fetchShipments(
        shipmentCode,
        numRefClient,
        startValue,
        endValue,
        this.state.size,
        defaultCurrentPage
      );

      this.props.setSearchParameters((prevState) => ({
        ...prevState,
        onDateRangePicked: false,
        onParameterPicked: false,
      }));
      this.setState({ currentPage: defaultCurrentPage });
    }
  }

  handlePagination(searchParameters, tableParameters) {
    const { shipmentCode, numRefClient, startValue, endValue } =
      searchParameters;
    const { size, currentPage, paginatedSearch } = tableParameters;

    if (paginatedSearch) {
      this.props.fetchShipments(
        shipmentCode,
        numRefClient,
        startValue,
        endValue,
        size,
        currentPage,
        paginatedSearch
      );

      this.setState({
        paginatedSearch: false,
      });
    }
  }

  setValues(check, telemetryTypeCode) {
    let iconType, color, message;

    switch (check) {
      case 0:
        iconType = "warning";
        color = "#f5d033";
        message = "No Gestionado";
        break;
      case 1:
        iconType = "check-circle";
        color = "#52C41A";
        if (telemetryTypeCode === 3) {
          message = "Offline (PDF)";
        } else if (telemetryTypeCode === 4) {
          message = "Online";
        } else if (telemetryTypeCode === 5) {
          message = "Offline (Datos)";
        }
        break;
      case 2:
        iconType = "close-circle";
        color = "#DB1010";
        if (telemetryTypeCode === 1) {
          message = "Sin Grabación";
        } else if (telemetryTypeCode === 2) {
          message = "Sin Datalogger";
        }
        break;
      case 3:
        iconType = "warning";
        color = "#0066ff";
        message = "Online (Envío sin Admisión)";
        break;
      default:
        iconType = "warning";
        color = "#f5d033";
        message = "No Gestionado";
        break;
    }

    return { iconType, color, message };
  }

  componentDidMount() {
    if (
      this.props.searchParameters.onDateRangePicked ||
      this.props.searchParameters.onParameterPicked
    ) {
      this.handleSearchOrders(this.props.searchParameters, this.state.size);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.searchParameters.onDateRangePicked ||
      this.props.searchParameters.onParameterPicked
    ) {
      this.handleSearchOrders(
        this.props.searchParameters,
        this.state.size,
        this.state.paginatedSearch
      );
    }

    if (this.state.paginatedSearch) {
      this.handlePagination(this.props.searchParameters, this.state);
    }

    if (prevProps.shipmentData !== this.props.shipmentData) {
      if (
        this.props.shipmentData.statusCode === 0 &&
        this.props.shipmentData.status === "paginating"
      ) {
        this.setState({
          mustShowTable: true,
          status: "paginating",
        });
        return false;
      }

      if (this.props.shipmentData.statusCode !== 200) {
        this.setState({
          message: this.props.shipmentData.message,
          status: this.props.shipmentData.status,
          statusCode: this.props.shipmentData.statusCode,
          mustShowTable: false,
        });
        return false;
      }

      if (this.props.shipmentData.statusCode === 200) {
        if (
          !this.props.shipmentData.data.length &&
          this.props.shipmentData.status === "loaded"
        ) {
          this.setState({
            statusCode: 204,
            status: "loaded",
            message: "No se han encontrado datos.",
            mustShowTable: false,
          });
          return false;
        }

        this.setState({
          dataSource: this.props.shipmentData.data,
          count: this.props.shipmentData.totalResults,
          dataToShow: this.props.shipmentData.data,
          status: this.props.shipmentData.status,
          statusCode: this.props.shipmentData.statusCode,
          currentPage: this.props.shipmentData.page,
          mustShowTable: true,
        });
      }
    }
  }

  render() {
    const {
      dataToShow,
      count,
      currentPage,
      size,
      status,
      statusCode,
      message,
      mustShowTable,
    } = this.state;
    const components = {
      body: {
        //cell: withTranslation()(EditableCell),
      },
    };

    const tableColumns = [
      {
        title: "Código de bulto",
        dataIndex: "shipmentCode",
        sorter: (a, b) => a.shipmentCode.localeCompare(b.shipmentCode),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 90,
        align: "center",
        ...this.getColumnSearchProps("shipmentCode"),
        render: (shipmentCode) => (
          <span>
            <Link to={"/envio?shipmentCode=" + shipmentCode}>
              {shipmentCode}
            </Link>
          </span>
        ),
      },
      {
        title: "IMEI (MAC)",
        dataIndex: "imei",
        sorter: (a, b) => a.imei.localeCompare(b.imei),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 85,
        align: "center",
        ...this.getColumnSearchProps("imei"),
        render: (imei, record) => (
          <>
            <span>{imei}</span>
            {this.renderMacData(record.macData)}
          </>
        ),
      },
      {
        title: "Ref. cliente / pedido",
        dataIndex: "numRefClient",
        sorter: (a, b) => a.numRefClient.localeCompare(b.numRefClient),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 75,
        align: "center",
        ...this.getColumnSearchProps("numRefClient"),
      },
      {
        title: "Datos",
        dataIndex: "check",
        editable: false,
        width: 50,
        align: "center",
        sorter: (a, b) => a.check - b.check,
        sortDirections: ["descend", "ascend"],
        render: (check, record) => {
          const { iconType, color, message } = this.setValues(
            check,
            record.telemetryTypeCode
          );
          return (
            <span style={{ color: color, fontSize: "1.5rem" }}>
              <Tooltip title={message}>
                <Icon type={iconType} />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: "Alertas",
        dataIndex: "warningsTotal",
        width: 55,
        align: "center",
        sorter: (a, b) => {
          const alertA = this.calculateTotalAlerts(a).props.children;
          const alertB = this.calculateTotalAlerts(b).props.children;
          return alertA.localeCompare(alertB);
        },
        sortDirections: ["descend", "ascend"],
        render: (_, record) => this.calculateTotalAlerts(record),
      },
      {
        title: "Remitente",
        dataIndex: "senderName",
        sorter: (a, b) => a.senderName.localeCompare(b.senderName),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 90,
        align: "center",
        ...this.getColumnSearchProps("senderName"),
      },
      {
        title: "Último evento",
        dataIndex: "eventName",
        sorter: (a, b) => a.eventName.localeCompare(b.eventName),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 70,
        align: "center",
        render: (eventName, row) => (
          <div
            style={{
              border: "1px solid #91D5FF",
              borderRadius: "4px",
              transform: "scale(0.8)",
              color: "#1990FF",
              background: "#E6F7FF",
              padding: ".2rem",
              minWidth: "100%",
              lineHeight: "1.3",
            }}
          >
            {!eventName ? "N/D" : eventName}
          </div>
        ),
      },
      {
        title: "Fecha y hora",
        dataIndex: "eventDate",
        sorter: (a, b) => a.eventDate - b.eventDate,
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 70,
        align: "center",
        render: (_, record) => {
          return <span>{this.formatDate(record.eventDate)}</span>;
        },
      },
      {
        title: "Codired",
        dataIndex: "eventCodired",
        sorter: (a, b) => a.eventCodired.localeCompare(b.eventCodired),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 60,
        align: "center",
        ...this.getColumnSearchProps("eventCodired"),
      },
      // {
      //   title: "Estado",
      //   key: "eventName",
      //   dataIndex: "eventName",
      //   sorter: (a, b) => a.eventName.localeCompare(b.eventName),
      //   sortDirections: ["descend", "ascend"],
      //   width: 120,
      //   filters: events.map((event) => {
      //     return { text: event, value: event };
      //   }),
      //   filterMultiple: true,
      //   onFilter: (value, record) => record.eventName.indexOf(value) === 0,
      //   render: (eventName, record) => {
      //     return (
      //       <span>{this.eventNameColor(eventName, record.eventDate)}</span>
      //     );
      //   },
      // },
      {
        title: "Ubicación del evento",
        dataIndex: "eventLocation",
        sorter: (a, b) => a.eventLocation.localeCompare(b.eventLocation),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 70,
        align: "center",
      },
      {
        title: "Destinatario",
        dataIndex: "destinationName",
        sorter: (a, b) => a.destinationName.localeCompare(b.destinationName),
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 90,
        align: "center",
        ...this.getColumnSearchProps("destinationName"),
      },
      {
        title: "Fecha alta en plataforma",
        dataIndex: "startDate",
        sorter: (a, b) => {
          return a.startDate.localeCompare(b.startDate);
        },
        sortDirections: ["descend", "ascend"],
        editable: false,
        width: 70,
        align: "center",
        render: (_, record) => {
          return <span>{this.formatDate(record.startDate)}</span>;
        },
      },
      {
        title: "POD",
        dataIndex: "pod",
        width: 35,
        align: "center",
        render: (_, record) => {
          const { shipmentCode, eventName } = record;
          const disabled = eventName !== "Entregado";
          return !disabled ? (
            <span style={{ fontSize: "1.7em" }}>
              <PODIcon
                type="file-protect"
                onClick={() => {
                  this.props.fetchShipmentPod(shipmentCode);
                }}
                style={{ color: "#000066", cursor: "pointer" }}
              />
            </span>
          ) : (
            <span style={{ fontSize: "1.7em" }}>
              <PODIcon type="file-protect" style={{ color: "#d4d5d3" }} />
            </span>
          );
        },
      },
    ];

    const columns = tableColumns.map((col) => {
      return col;
    });

    const pagProps = {
      total: count,
      showSizeChanger: true,
      showQuickJumper: true,
      showTotal: (total) => `${total} resultados`,
      hideOnSinglePage: false,
      current: self.state.currentPage,
      pageSize: size,
      pageSizeOptions: ["10", "20", "40", "80"],
    };

    const paginationProps = {
      bordered: true,
      footer: () => {
        return (
          <Row type="flex" justify="end">
            <Pagination
              {...pagProps}
              onChange={this.handlePagChange}
              onShowSizeChange={this.handlePagSizeChange}
            />
          </Row>
        );
      },
      // rowSelection: {},
      rowClassName: () => "editable-row",
    };

    return (
      <div className="Envios">
        <h2>{this.props.t("common.trackingsList")}</h2>

        {status === "loading" && (
          <>
            <Skeleton active />
          </>
        )}

        {statusCode !== 200 && status === "loaded" && (
          <>
            <p>{message}</p>
          </>
        )}

        {mustShowTable && (
          <EditableContext.Provider value={this.props.form}>
            <Spin
              tip="Cargando..."
              spinning={status === "paginating"}
              size="large"
              style={{ transform: "scale(2)" }}
            >
              <Table
                components={components}
                scroll={{ x: 500 }}
                {...paginationProps}
                dataSource={dataToShow}
                columns={columns}
                pagination={false}
              />
            </Spin>
          </EditableContext.Provider>
        )}
      </div>
    );
  }
}

export default withTranslation()(Form.create()(DetailedTracksTable));
