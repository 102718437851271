import { Home, Ayuda, Shipment } from '../pages';

const pathRoutes = [
  {
    path: '/',
    component: Home,
    label: 'router_routes.home',
    public: true
  }
  ,{
    path: '/envio',
    component: Shipment,
    label: 'Envío',
    public: false
  }
  ,{
    path: '/ayuda',
    component: Ayuda,
    label: 'Ayuda',
    public: false
  }
];

export default pathRoutes;
