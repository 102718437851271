import React, { PureComponent } from "react";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";
import { useEffect } from "react";

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

const CustomizedDot = (props) => {
  const { cx, cy, value, max, min } = props;

  if (!max && !min) {
    return false;
  }

  if (max !== null && value > max) {
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        width={20}
        height={20}
        fill="red"
        viewBox="64 64 896 896"
      >
        <path d="M955.7 856l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zM480 416c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v184c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V416zm32 352a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path>
      </svg>
    );
  }

  if (min !== null && value < min) {
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        width={20}
        height={20}
        fill="red"
        viewBox="64 64 896 896"
      >
        <path d="M955.7 856l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zM480 416c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v184c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V416zm32 352a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path>
      </svg>
    );
  }

  return false;
};

function ShipmentCharts({ trackNum, chartsInfo }) {
  const [chartParameters, setChartParameters] = useState({
    message: "Cargando...",
    status: "loading",
    statusCode: 0,
    track_num: trackNum,
    telemetryInfo: [],
    tempMax: 0,
    tempMin: 0,
    maxRecord: 0,
    minRecord: 0,
  });

  useEffect(() => {
    if (chartsInfo.statusCode === 200) {
      setChartParameters((prevState) => ({
        ...prevState,
        ...chartsInfo,
        status: "loaded",
        maxRecord: Math.max(
          ...chartsInfo.telemetryInfo.map((data) => data.temp)
        ),
        minRecord: Math.min(
          ...chartsInfo.telemetryInfo.map((data) => data.temp)
        ),
      }));
    }

    if (chartsInfo.statusCode !== 200) {
      setChartParameters((prevState) => ({
        ...prevState,
        message: "No se han encontrado datos de telemetría para este envío.",
        status: "loaded",
        statusCode: chartsInfo.statusCode,
      }));
    }
  }, [chartsInfo]);

  const getMaxDomainValue = (maxRecord) => {
    const { tempMax, tempMin } = chartParameters;

    if (tempMax === null && tempMin !== null && tempMin > maxRecord) {
      return Math.ceil(tempMin + 2);
    }

    if (tempMax === null || maxRecord > tempMax) {
      return Math.ceil(maxRecord + 2);
    }

    return Math.ceil(tempMax + 2);
  };

  const getMinDomainValue = (minRecord) => {
    const { tempMax, tempMin } = chartParameters;

    if (tempMin == null && tempMax !== null && tempMax < minRecord) {
      return Math.floor(tempMax - 2);
    }

    if (tempMin === null || minRecord < tempMin) {
      return Math.floor(minRecord - 2);
    }

    return Math.floor(tempMin - 2);
  };

  return (
    <div className="Charts">
      {chartParameters.status === "loading" ||
      chartParameters.statusCode !== 200 ? (
        <>
          <p>{chartParameters.message}</p>
        </>
      ) : (
        <Row gutter={16}>
          <Col span={24}>
            <h3>Temperatura</h3>
            <div style={{ width: "100%", height: 400 }}>
              <ResponsiveContainer>
                <LineChart
                  data={chartParameters.telemetryInfo}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 80,
                    left: 50,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
                  <YAxis
                    unit="&#176;C"
                    domain={[
                      getMinDomainValue(chartParameters.minRecord),
                      getMaxDomainValue(chartParameters.maxRecord),
                    ]}
                  />
                  <Tooltip />

                  <ReferenceLine
                    y={chartParameters.tempMax}
                    label="Max"
                    stroke="#ffbaba"
                  />
                  <ReferenceLine
                    y={chartParameters.tempMin}
                    label={"Min"}
                    stroke={"#ffbaba"}
                  />
                  <Line
                    type="monotone"
                    dataKey="temp"
                    unit="&#176;C"
                    name="Temperatura"
                    stroke="#82ca9d"
                    dot={
                      <CustomizedDot
                        max={chartParameters.tempMax}
                        min={chartParameters.tempMin}
                      />
                    }
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col span={24}>
            <h3>Humedad</h3>
            <div style={{ width: "100%", height: 400 }}>
              <ResponsiveContainer>
                <LineChart
                  data={chartParameters.telemetryInfo}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 80,
                    left: 50,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
                  <YAxis unit="%" />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="humidity"
                    name="Humedad"
                    unit="%"
                    stroke="#8884d8"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      )}{" "}
    </div>
  );
}

export default withTranslation()(ShipmentCharts);
