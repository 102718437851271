/* eslint-disable no-console */
import axios from 'axios';
import { storeArq } from 'correos-arq-utils';
import { CatchPerm } from 'correos-arq-auth';
import moment from 'moment';
import rolesWeb from '../router/rolesweb';

export const instance = axios.create();

// Add a request interceptor
instance.interceptors.request.use(async (config) => {
  //Controlar la expiracion del token
  let expiracion = storeArq.data.auth.exp;
  let creacion = storeArq.data.auth.iat;
  let current = storeArq.data.auth.current;
  let desfase = current - creacion;
  let fechaActPet = Math.round(moment().add(-20, 'seconds').valueOf() / 1000);

  // Configurar el idioma
  let CurrentLng = storeArq.data.auth.userInfo.userlanguage;
  config.headers['Accept-Language'] = CurrentLng;
  
  if (expiracion < (fechaActPet - desfase)) {
    console.log("Token expirado...comenzar proceso de ExpireAuth...");
    const tokenRefrescado = await CatchPerm.expireAuth(rolesWeb);

    // Do something before request is sent
    // console.log('interceptors.request.config: ', config);
    config.headers.common.Authorization = 'Bearer ' + tokenRefrescado;
  } else {
    // Do something before request is sent
    // console.log('interceptors.request.config: ', config);
    config.headers.common.Authorization = 'Bearer ' + storeArq.data.auth.idToken;
  }
  return config;
}, (error) => {
  // Do something with request error
  console.error('interceptors.request.error: ', error);
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use((response) => {
  /* Any status code that lie within the range of 2xx cause this function to trigger
  (Do something with response data)*/
  return response;
}, /* async */(error) => {
  /* Any status codes that falls outside the range of 2xx cause this function to trigger
  (Do something with response error)*/
  /* const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const tokenRefrescado = await CatchPerm.catchAuth(error.response.status, rolesWeb);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenRefrescado;
    return instance(originalRequest);
  } */
  // console.log('interceptors.response', error.config);
  return Promise.reject(error);
});

export default instance;
