import React from "react";
import { withTranslation } from "react-i18next";
import { Skeleton } from "antd";
import StandAloneShipmentData from "../StandAloneShipmentData/StandAloneShipmentData";
import OnlineDataLoggerShipmentData from "../OnlineDataLoggerShipmentData/OnlineDataLoggerShipmentData";

function ShipmentContentData({
  shipmentParameters,
  telemetryInfo,
  alertsListInfo,
  chartsInfo,
}) {
  if (shipmentParameters.status === "loading") {
    return (
      <>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </>
    );
  }

  if (
    shipmentParameters.statusCode !== 200 &&
    shipmentParameters.status === "loaded"
  ) {
    return (
      <>
        <p>{shipmentParameters.message}</p>
      </>
    );
  }

  if (
    shipmentParameters.statusCode === 200 &&
    shipmentParameters.status === "loaded"
  ) {
    return (
      <div>
        {shipmentParameters.standalone ? (
          <StandAloneShipmentData
            shipmentParameters={shipmentParameters}
            telemetryInfo={telemetryInfo}
            alertsListInfo={alertsListInfo}
            chartsInfo={chartsInfo}
          />
        ) : (
          <OnlineDataLoggerShipmentData
            shipmentParameters={shipmentParameters}
            telemetryInfo={telemetryInfo}
            alertsListInfo={alertsListInfo}
            chartsInfo={chartsInfo}
          />
        )}
      </div>
    );
  }

  return null;
}

export default withTranslation()(ShipmentContentData);
