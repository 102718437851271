import Authorize from '../Api/Auth/Authorize';
import LoginCallback from '../Api/Auth/LoginCallback';
import LoginChecker from '../Api/Auth/LoginChecker';

const authRoutes = [
  {
    type: "function",
    path: '/login',
    component: LoginChecker
  },
  {
    type: "function",
    path: '/callback',
    component: LoginCallback,
  },
  {
    type: "function",
    path: '/authorize',
    component: Authorize,
  }
];

export default authRoutes;