/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import React, { FunctionComponent, useState } from 'react';
import { Row, Col, Icon, Dropdown, Tooltip, Menu, Select, Avatar } from 'antd';
import MUser from './MUser';
import LogoCorreos from '../../../public/images/LogoCorreos2019_Azul.svg';
import { Link } from 'react-router-dom';
import 'moment/locale/es';
import { withTranslation } from 'react-i18next';
import { storeArq, Language, checkIsNull } from 'correos-arq-utils';

const { Option } = Select;
const { SubMenu } = Menu;
const Header = (myprops) => {
  try {

    const { t, clickHome, clickMenu, i18n, icons } = myprops;
    storeArq.load();
    require('dotenv').config();
    const [language, setLanguage] = useState('es-ES');

    /* Logo y nombre de aplicacion (name)
    + barra de herramientas (notif, home y ayuda)
    + dropdown auto --> MUser, responsive con el sistema Grid
    
    Si estas autenticado aparecerá el menu usuario con sus respectivos datos
    y el logo redicionando a al inicio ,en caso negativo, en el menu usuario no aparecerá nada
    y en el logo NO redicionará a al inicio */

    let pathHelp = "#";
    let classPathHelp = "";
    let classPathHelpIcon = "";

    let classLang = "";
    let classLangIcon = "";

    if (i18n.languages.length > 1) {
      classLang = "Items";
      classLangIcon = "SelectIdiom visible";
    } else {
      classLang = "Items nonDisplay";
      classLangIcon = "SelectIdiom nonDisplay";
    }

    let logout = false;

    if (myprops.logoutSys) {
      logout = myprops.logoutSys;
    };

    if (myprops.pathHelp) {
      pathHelp = myprops.pathHelp;
    };

    if (pathHelp === "#") {
      classPathHelp = "Items nonDisplay";
      classPathHelpIcon = "nonDisplay";
    } else {
      classPathHelp = "Items";
      classPathHelpIcon = "";
    }


    const changeLanguageMenu = (lng) => {
      i18n.changeLanguage(lng);
    };

    const changeLanguageSelect = (value) => {
      i18n.changeLanguage(value);
      setLanguage(value);
    }

    const altProp = t('components_shared_Header.imgAlt');
    let headerAuth;
    let userIcon;

    const menuBurger = (
      <Menu className="MenuHeader">
        <SubMenu className={classLang} title={
          <div>
            <Icon type="flag" />
            <span style={{ marginLeft: '0.125em', marginRight: '0.4em' }}>{t('components_shared_Header.iconsTooltip.4')}</span>
          </div>
        }
        >
          {i18n.languages.map((lang, i) => (
            <Menu.Item key={i} onClick={() => changeLanguageMenu(lang)}>{t(`components_shared_Header.lenguages.${lang}`)}</Menu.Item>
          ))}
        </SubMenu>
        <Menu.Item key="2" className="Items">
          <div>
            <Icon type="home" style={{ marginRight: '0.53em' }} />
            <Link
              to="/"
              onClick={() => {
                clickHome(true);
                clickMenu && clickMenu();
                
                storeArq.data.auth.userInfo.subMenu = {};
                storeArq.save();
              }}
            >
              <span style={{ color: 'rgba(0,0,0,.65)' }}>{t('components_shared_Header.iconsTooltip.1')}</span>
            </Link>
          </div>
        </Menu.Item>
        <Menu.Item key="3" className={classPathHelp}>
          <div>
            <Icon type="question-circle" style={{ marginRight: '0.53em' }} />
            <Link to={pathHelp}>
              <span >{t('components_shared_Header.iconsTooltip.2')}</span>
            </Link>
          </div>
        </Menu.Item>
        {
          (!checkIsNull(icons)) ?
            
            icons.map((icon, i) => (
              <Menu.Item key={i}>
                <div>
                  {icon.burgerComponent}
                  <span> {icon.burgerLabel} </span>
                </div>
              </Menu.Item>)
            )
            :
            []
        }
      </Menu>
    );

    if (storeArq.data.auth.idToken !== '') {
      userIcon = (
        
        <Col xs={2} sm={2} md={2} lg={1} xl={1} xxl={1} align="left">
          <div className="icons-user">
            <Dropdown overlay={<MUser logoutSys={logout} />}>
              <Tooltip placement="bottom" title={t('components_shared_Header.iconsTooltip.3')}>
                <div className="BoxAvatar">
                  <div className="Line" />
                  <Avatar style={{ backgroundColor: '#0066FF', cursor: 'pointer' }} icon="user" className="iconos-user" size={25} />
                </div>
              </Tooltip>
            </Dropdown>
          </div>
        </Col>
      );

      headerAuth = (
        <Row className="Head">
          <Col span={1} />
          <Col xs={14} sm={14} md={14} lg={15} xl={16} xxl={16} >
            <Link
              to="/"
              onClick={() => {
                clickHome(true);
                clickMenu && clickMenu();
                
                storeArq.data.auth.userInfo.subMenu = [];
                storeArq.save();
              }}
            >
              <img src={LogoCorreos} alt={altProp} className="Logo" />
            </Link>
            <p className="overflowTitle">{t('App.title')}</p>
          </Col>
          {/* @ts-ignore */}
          <Col xs={7} sm={7} md={7} lg={6} xl={5} xxl={6} align="right">
            <div className="icons overflowIcon">
              <Tooltip placement="left" title={t('components_shared_Header.iconsTooltip.4')}>
                <Select value={Language.getCurrentLng(i18n, language)} onChange={changeLanguageSelect} className={classLangIcon}>
                  {i18n.languages.map((lang, i) => (
                    <Option key={i} value={lang}>{t(`components_shared_Header.lenguages.${lang}`)}</Option>
                  ))}
                </Select>
              </Tooltip>
              <Tooltip placement="bottom" title={t('components_shared_Header.iconsTooltip.1')}>
                <Link
                  to="/"
                  onClick={() => {
                    clickHome(true);
                    clickMenu && clickMenu();
                    
                    storeArq.data.auth.userInfo.subMenu = {};
                    storeArq.save();
                  }}
                >
                  <Icon className="iconos visible" type="home" />
                </Link>
              </Tooltip>
              <Tooltip className={classPathHelpIcon} placement="bottom" title={t('components_shared_Header.iconsTooltip.2')}>
                <Link to={pathHelp}>
                  <Icon className="iconos visible" type="question-circle" />
                </Link>
              </Tooltip>
              {
                (!checkIsNull(icons)) ?
                  
                  icons.map((icon, i) => (
                    <Tooltip placement="bottom" title={icon.label} key={i}>
                      {icon.component}
                    </Tooltip>)
                  )
                  :
                  []
              }
              <Dropdown overlay={menuBurger} >
                <Tooltip placement="left" title={t('components_shared_Header.iconsTooltip.5')}>
                  <Icon className="Burguer" type="bars" />
                </Tooltip>
              </Dropdown>
            </div>
          </Col>
          {userIcon}
          <Col span={1} />
        </Row >
      );
    } else {
      headerAuth = (
        <Row>
          <Col span={1} />
          <Col xs={14} sm={14} md={14} lg={15} xl={16} xxl={16}>
            <img src={LogoCorreos} alt={altProp} className="Logo" />
            <p className="overflowTitle">{t('App.SinAuth') + " " + t('App.title')}</p>
          </Col>
          {/* @ts-ignore */}
          <Col xs={8} sm={8} md={8} lg={7} xl={6} xxl={6} align="right">
            <Tooltip placement="left" title={t('components_shared_Header.iconsTooltip.4')}>
              <Select value={Language.getCurrentLng(i18n, language)} onChange={changeLanguageSelect} className={classLangIcon}>
                {i18n.languages.map((lang, i) => (
                  <Option key={i} value={lang}>{t(`components_shared_Header.lenguages.${lang}`)}</Option>
                ))}
              </Select>
            </Tooltip>
          </Col>
          <Col span={1} />
        </Row>
      );
    }
    return (
      <div className="header">
        {headerAuth}
      </div>
    );
  } catch (error) {
    throw new Error(`Error: ${error}`);
  }
}

export default withTranslation()(Header);
