const rolesWebCId = {
  accion: [
    { path: "/", sub: null },
    { path: '/envio', sub: null },
    { path: '/ayuda', sub: null }
  ],
  label: 'Usuario',
};

export default rolesWebCId;
