/* eslint-disable prettier/prettier */
import { authCid } from '../ApiUrl'
import { storeArq } from 'correos-arq-utils';
import axios from 'axios';

const createHistory = require('history').createBrowserHistory;

const useLogout = () => {
  const history = createHistory({ forceRefresh: true });

  const logout = async () => {
    try {
      const canceltoken = await axios.post(authCid.cancelToken, {
        idToken: storeArq.data.auth.idToken
      }, {
        headers: {
          'ApplicationOid': window._env_.REACT_APP_CLIENT_ID_C_ID
        }
      });
  
      if (canceltoken.status === 200) {
        storeArq.delete();
        return history.push('/');
      }
  
      if (canceltoken.status !== 200) {
        storeArq.delete();
        return history.push('/')
      }
      
    } catch (err) {
      console.log("useLogout", err);
      storeArq.delete();
      return history.push('/')
    }
  }

  return { logout }

};

export default useLogout;