import axios from 'axios';
import { storeArq } from 'correos-arq-utils';
import React, { useEffect } from 'react'
import { authCid } from '../ApiUrl';

const LoginChecker = () => {
  
  const checkIsLoggedIn = async () => {
    storeArq.load();
    try {
      const userdata = await axios.get(authCid.userDate, {
        headers: {
          'ApplicationOid': window._env_.REACT_APP_CLIENT_ID_C_ID,
          'Authorization': 'Bearer ' + storeArq.data.auth.idToken
        }
      });

      if (userdata.status == 200) {
        return false
      } else {
        return window.location.replace(window._env_.REACT_APP_ENDPOINT_AUTH_CID)
      }
      
    } catch (error) {
      return window.location.replace(window._env_.REACT_APP_ENDPOINT_AUTH_CID)
    }
  };

  useEffect(() => {
    (async () => {
      await checkIsLoggedIn();
    } )();
  }, []);

  return ( <div /> )
}

export default LoginChecker