/*eslint-env node*/

const auth = {
  getTokenISAM:
    window._env_.REACT_APP_ENDPOINT_OAUTH_ISAM + "/mga/sps/oauth/oauth20/",
  logoutISAM: window._env_.REACT_APP_ENDPOINT_OAUTH_ISAM + "/pkmslogout",
  refreshTokenISAM:
    window._env_.REACT_APP_ENDPOINT_OAUTH_ISAM + "/[refreshTokenApi]",
  getTokenC_ID:
    window._env_.REACT_APP_ENDPOINT_OAUTH_C_ID +
    "/myidentity/notifier/signinorsignup",
  logoutC_ID:
    window._env_.REACT_APP_ENDPOINT_OAUTH_C_ID + "/myidentity/notifier/singout",
  refreshTokenC_ID:
    window._env_.REACT_APP_ENDPOINT_OAUTH_C_ID +
    "/myidentity/notifier/RefreshToken",
  userDataC_ID:
    window._env_.REACT_APP_ENDPOINT_OAUTH_API_C_ID + "/api/Accounts/user",
};

const back = {
  getInfoEnvio: window._env_.REACT_APP_ENDPOINT_INFO_ENVIO + "/envio/",
  getListEnvios:
    window._env_.REACT_APP_ENDPOINT_INFO_ENVIO + "/container/shipmentList",
  // getListAlerts: window._env_.REACT_APP_ENDPOINT_INFO_ENVIO + '/alertsList',
  getShipmentInfo:
    window._env_.REACT_APP_ENDPOINT_INFO_ENVIO + "/container/byShipmentCode",
  // getTrackInfoEvents: window._env_.REACT_APP_ENDPOINT_INFO_ENVIO + '/trackInfo/{track_num}/events',
  // getTrackInfoInfo: window._env_.REACT_APP_ENDPOINT_INFO_ENVIO + '/trackInfo/{track_num}/telemetryInfo',
  // getTrackInfoData: window._env_.REACT_APP_ENDPOINT_INFO_ENVIO + '/trackInfo/{track_num}/telemetryData'
  fetchShipmentPod:
    window._env_.REACT_APP_ENDPOINT_INFO_ENVIO + "/container/pod",
};

const authCid = {
  getAuthToken: window._env_.REACT_APP_ENDPOINT_API_C_ID + "/api/Login",
  refreshToken:
    window._env_.REACT_APP_ENDPOINT_API_C_ID + "/api/Login/RefreshToken",
  userDate: window._env_.REACT_APP_ENDPOINT_API_C_ID + "/api/ProfileUser",
  cancelToken:
    window._env_.REACT_APP_ENDPOINT_API_C_ID + "/api/Login/CancelToken",
};

module.exports = {
  auth,
  back,
  authCid,
};
